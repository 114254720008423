import React from 'react';
import { ThemeProvider, useTheme } from './ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const App = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <div
            className={`min-h-screen flex flex-col items-center justify-center ${
                theme === 'dark' ? 'bg-[#0d1117] text-white' :
                    theme === 'light' ? 'bg-white text-blue-900' :
                        'bg-black text-white' // AMOLED
            }`}
        >
            <div className="flex flex-col items-center justify-center flex-grow">
                {/* Image and Text */}
                <div className="flex items-center justify-center mb-5">
                    <img
                        src="/activitydots.webp"  // Update this path to the correct location of your .webp file
                        alt="ActivityDots.com Logo"
                        className="w-[40px] h-[40px] md:w-[70px] md:h-[70px] mr-2  md:mr-4"
                    />
                    <div className="text-[2rem] md:text-[4rem] flex">
                        <span className="font-light">ActivityDots</span>
                        <span className="font-thin">.com</span>
                    </div>
                </div>
                {/* Email with Icon */}
                <div className="flex items-center text-md text-gray-500 dark:text-gray-400 mb-5">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    <span>support@activitydots.com</span>
                </div>
            </div>
            {/* Small Toggle Theme Button */}
            <div className="mb-5">
                <button
                    className="px-2 py-1 text-xs bg-gray-200 dark:bg-gray-800 text-black dark:text-white rounded"
                    onClick={toggleTheme}
                >
                    Toggle Theme
                </button>
            </div>
        </div>
    );
};

const AppWrapper = () => (
    <ThemeProvider>
        <App />
    </ThemeProvider>
);

export default AppWrapper;
